import axios from '../config/axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InfoCard from '../components/InfoCard';
import moment from 'moment';

function Home() {
  const [data, setData] = useState([
    {
      name: 'Pembangunan Pengendali Banjir di Kawasan Mandalika',
      paket: [
        {
          title: 'Terhadap Pagu Anggaran 2022',
          rencana: '18.297 %',
          realisasi: '19.057 %',
          deviasi: '0.130 %',
        },
      ],
      bg_color: 'sky',
    },
    {
      name: 'Air Baku Bendungan Pengga untuk Kek Mandalika',
      paket: [
        {
          title: 'Terhadap Pagu Anggaran 2022',
          rencana: '18.297 %',
          realisasi: '19.057 %',
          deviasi: '0.130 %',
        },
      ],
      bg_color: 'blue',
    },
    {
      name: 'Bendungan Meninting',
      paket: [
        {
          title: 'Paket I',
          rencana: '18.297 %',
          realisasi: '19.057 %',
          deviasi: '0.130 %',
        },
        {
          title: 'Paket II',
          rencana: '18.297 %',
          realisasi: '19.057 %',
          deviasi: '0.130 %',
        },
      ],
      bg_color: 'teal',
    },
    {
      name: 'Bendungan Beringin Sila',
      paket: [
        {
          title: 'Paket I',
          rencana: '18.297 %',
          realisasi: '19.057 %',
          deviasi: '0.130 %',
        },
        {
          title: 'Paket II',
          rencana: '18.297 %',
          realisasi: '19.057 %',
          deviasi: '0.130 %',
        },
      ],
      bg_color: 'purple',
    },
    {
      name: 'Bendungan Tiu Suntuk',
      paket: [
        {
          title: 'Paket I',
          rencana: '18.297 %',
          realisasi: '19.057 %',
          deviasi: '0.130 %',
        },
        {
          title: 'Paket II',
          rencana: '18.297 %',
          realisasi: '19.057 %',
          deviasi: '0.130 %',
        },
      ],
      bg_color: 'yellow',
    },
    {
      name: 'Pembangunan Irigasi Bintang Bano',
      paket: [
        {
          title: 'Paket I',
          rencana: '18.297 %',
          realisasi: '19.057 %',
          deviasi: '0.130 %',
        },
        {
          title: 'Paket II',
          rencana: '18.297 %',
          realisasi: '19.057 %',
          deviasi: '0.130 %',
        },
      ],
      bg_color: 'orange',
    },
    {
      name: 'Pembangunan Irigasi Bintang Bano',
      paket: [
        {
          title: 'Paket I',
          rencana: '18.297 %',
          realisasi: '19.057 %',
          deviasi: '0.130 %',
        },
        {
          title: 'Paket II',
          rencana: '18.297 %',
          realisasi: '19.057 %',
          deviasi: '0.130 %',
        },
      ],
      bg_color: 'orange',
    },
    {
      name: 'Persiapan O&P Bendungan Bintang Bano',
      paket: [
        {
          title: 'Terhadap Pagu Anggaran 2022',
          rencana: '18.297 %',
          realisasi: '19.057 %',
          deviasi: '0.130 %',
        },
      ],
      bg_color: 'rose',
    },
    {
      color: 'teal',
      nama: 'Bendungan Meninting',
      paket: [
        {
          nama: 'Paket I',
          rencana: '18.297',
          realisasi: '19.057',
          deviasi: '0.130',
        },
        {
          nama: 'Paket II',
          rencana: '18.297',
          realisasi: '19.057',
          deviasi: '0.130',
        },
      ],
    },
  ]);

  const [dataProg, setDataProg] = useState([]);
  const [infrasDate, setInfrasDate] = useState([]);

  useEffect(() => {
    fetchInfrasDate();
    fetchData();
  }, []);

  const fetchInfrasDate = async () => {
    try {
      const res = await axios.get('/api/progress-infrastruktur/date/first');
      setInfrasDate(res.data.data.progress);
    } catch (error) {
      console.error(error.response);
    }
  };

  const fetchData = async () => {
    try {
      const res = await axios.get('/api/progress-infrastruktur');
      console.log('res', res);
      setDataProg(res.data.data.progress_infrastruktur);
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <div className="h-full min-h-screen w-full bg-gray-200">
      <div className="fixed top-0 left-0 z-0 h-screen w-screen opacity-50">
        <img src="/sd.jpg" alt="" className="h-full w-full object-cover" />
      </div>
      <div className="relative z-10 min-h-screen">
        <div className="flex h-full min-h-screen w-full flex-col justify-between">
          <div className="grid w-full grid-cols-1 gap-4 p-8 md:grid-cols-2 xl:grid-cols-6">
            <div className="md:col-span-2 xl:col-span-4">
              <div>
                <div className="mb-4 -ml-8 inline-block rounded-r-full bg-white py-2 pl-8 pr-16 shadow-md">
                  <img src="/Logo_PU_PU_2baris_1.png" alt="" className="h-16" />
                </div>
                <div className="">
                  <h1 className="text-5xl font-bold uppercase text-orange-500">
                    Progres <br></br> Pekerjaan Fisik
                  </h1>
                  <h1 className="text-4xl font-bold uppercase text-[#254475] md:text-5xl">
                    Infrastruktur SDA <br></br> BBWS NT I Mataram
                  </h1>
                  <div className="mt-4 inline-block rounded-full bg-orange-500 px-8 py-2 text-xl font-bold text-white">
                    {infrasDate &&
                      moment(infrasDate.tanggal, 'YYYY-MM-DD').format(
                        'DD MMMM YYYY'
                      )}
                  </div>
                </div>
              </div>
            </div>
            {dataProg &&
              dataProg.map((d, i) => (
                <InfoCard
                  key={i}
                  name={d.nama}
                  paket={d.paket}
                  bgcolor={d.color}
                />
              ))}
          </div>
          <div className="bg-[#254475] ">
            <div className="mx-auto flex w-full max-w-7xl justify-center py-2 px-8 text-white">
              <div>2022@BWS Nusa Tenggara I</div>
              {/* <span className="ml-4 opacity-50">
                <Link to="/dashboard">Dashboard</Link>
              </span> */}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden">
        <div className="text-sky-900"></div>
        <div className="text-sky-800"></div>
        <div className="from-sky-400"></div>
        <div className="to-sky-500"></div>
        <div className="to-sky-600"></div>

        <div className="text-blue-900"></div>
        <div className="text-blue-800"></div>
        <div className="from-blue-400"></div>
        <div className="to-blue-500"></div>
        <div className="to-blue-600"></div>

        <div className="text-teal-900"></div>
        <div className="text-teal-800"></div>
        <div className="from-teal-400"></div>
        <div className="to-teal-500"></div>
        <div className="to-teal-600"></div>

        <div className="text-emerald-900"></div>
        <div className="text-emerald-800"></div>
        <div className="from-emerald-400"></div>
        <div className="to-emerald-500"></div>
        <div className="to-emerald-600"></div>

        <div className="text-purple-900"></div>
        <div className="text-purple-800"></div>
        <div className="from-purple-400"></div>
        <div className="to-purple-500"></div>
        <div className="to-purple-600"></div>

        <div className="text-yellow-900"></div>
        <div className="text-yellow-800"></div>
        <div className="from-yellow-400"></div>
        <div className="to-yellow-500"></div>
        <div className="to-yellow-600"></div>

        <div className="text-orange-900"></div>
        <div className="text-orange-800"></div>
        <div className="from-orange-400"></div>
        <div className="to-orange-500"></div>
        <div className="to-orange-600"></div>

        <div className="text-rose-900"></div>
        <div className="text-rose-800"></div>
        <div className="from-rose-400"></div>
        <div className="to-rose-500"></div>
        <div className="to-rose-600"></div>
      </div>
    </div>
  );
}

export default Home;
